import axios from "axios";

import { useAuth } from "@contexts/auth";
import { AppointmentType } from "@lib/data/schemas/appointment";

import { useUpdateAPICache } from "./use-update-swr-cache";

type UpdateAppointmentPayload = {
  appointmentId: string;
  data: Partial<AppointmentType>;
};

export const useUpdateAppointmentsCache = () => {
  const { updateAPICache } = useUpdateAPICache();
  const { oid } = useAuth();

  const onUpdateAppointment = async (appointmentId: string) => {
    const { data } = await axios.get(
      `/api/v1/organizations/${oid}/appointments/${appointmentId}`
    );

    await updateCache(
      [
        {
          appointmentId: data.id,
          data,
        },
      ],
      false
    );
  };

  const updateCache = async (
    updates: UpdateAppointmentPayload[],
    merge: boolean = true
  ) => {
    if (!oid) return;

    const appointmentsQueryKey = `/api/v1/organizations/${oid}/appointments?`;
    const formattedUpdates = updates.map(({ appointmentId, data }) => ({
      id: appointmentId,
      data,
    }));

    await updateAPICache(appointmentsQueryKey, formattedUpdates, merge);
  };

  return {
    updateCache,
    onUpdateAppointment,
  };
};
